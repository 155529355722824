import React from 'react'

import Layout from '@/components/layout'
import SEO from '@/components/seo'

import Image from '@/components/image'

import './style.css'

const AboutPage = () => (
	<Layout>
		<SEO title="About" />
		<div className="about">
			<h2 className="title">关于博客</h2>
			<hr/>
			<Image className="avatar" />
			<h4>为啥不用博客站点，非要自己做一个博客？</h4>
			<p>2010年第一次接触博客，一晃已经过去了好多年。</p>
			<p>
				一开始跟其他人一样，也是在一个博客站点写博客。当时，热情很高，曾经做过的笔记一一发到博客上。
				还把珍藏的口琴谱拿出来分享。因为有些口琴谱是自己根据曲子还原出来的，网上我这里是独一家，于是给博客带来了不错的访问量，有时一天能有个上千的访问量。
			</p>
			<p>
				2012年走完青藏线，把路上的点点滴滴通过博文的方式分享了出来。忙了一阵子很快到了年底，回头看之前写的文章发现很多都不翼而飞了，其实就是青藏线后半截的内容消失了。
				可能是内容的原因，也可能是博文的配图，总之就那么消失了。
			</p>
			<p>
				虽然，跟站点的客服交流了很多次，但结论是一样的：他们从来没动作我的文章。
				当时还从来没有想过自己做博客，愤怒之后的第一个念头就是换个博客站点。
				折腾了半天，总算把原博客的内容搬到了新浪博客上。不过，每种博客都是有自己的特点的，不可能每个功能都跟以前一样。
				在适应新功能的过程中，总算冷静了下来，也能有心力去思考之前发生的事情。只要大环境是一样的，那么再怎么挪站点都是于事无补的，文章还是一样被删除。
			</p>
			<p>
				试着在几个国外博客站点注册了账号尝试了下。但很快就放弃了。说实话，博客功能实在是太弱了，界面也不是很好看，整体感觉很糟糕。
				这次尝试也令我萌生了是不是可以自己做个博客的想法。
			</p>
			<p>
				有了目标事情基本就成一半了。在网上找了很多文章，还是有很多牛人也曾遭遇过跟我类似的经历，于是他们自己做了博客，还把宝贵的经验分享了出来。
				参考大牛们的文章，最后在github上安了家。整体来说还不错，文章也很容易发布，界面也可以自己动手优化，对于程序员来说再适合不过了。
			</p>
			<hr/>
			<h4>博客都发些什么内容</h4>
			<p>作为程序员肯定少不了技术文章，可以给自己的成长留下脚印。</p>
			<p>
				有一阵挺喜欢订阅和阅读公众号上的文章，看到精彩之处总是要收藏回头再看看。
				也许是因为公众号的文章亦是博文，也免不了同样的命运，曾经收藏的文章有时候就再也无缘再见了。
				于是开始将不错的、有感触的文章转录到博客上，至少也给这些文章一些机会，哪怕被迫不翼而飞，也至少有个地方可以追溯。
			</p>
		</div>
	</Layout>
)

export default AboutPage
